import React from "react";
import { Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ClientCreatedNotification = ({ notification, isAdmin }) => {
  const {
    client_id: clientId,
    client_name: clientName,
    created_by: creatorName,
  } = notification.data;
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="body2">
        A new client named {clientName} was created by {creatorName}.
      </Typography>
      <Link
        component="button"
        variant="subtitle1"
        onClick={() =>
          navigate(
            isAdmin ? `/admin/clients/${clientId}` : `/clients/${clientId}`
          )
        }
        underline="none"
      >
        View {clientName}'s profile
      </Link>
    </>
  );
};

export default ClientCreatedNotification;
