import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CustomDatePicker = ({ name, date, setDate, label, disabled = false }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        name={name}
        format="MM/DD/YYYY"
        label={label}
        value={date}
        onChange={setDate}
        disabled={disabled}
        slotProps={{ textField: { size: "small" } }}
        sx={{ width: "100%", my: 1 }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
