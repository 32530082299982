import axiosInstance from "./apiService";
import { USER_API_ENDPOINTS } from "./apiConstants";

export const login = async (values) => {
  try {
    const response = await axiosInstance.post(USER_API_ENDPOINTS.LOGIN, values);
    return { success: true, response };
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message || "Login failed. Please try again.",
    };
  }
};

export const logout = async () => {
  try {
    await axiosInstance.get(USER_API_ENDPOINTS.LOGOUT);
  } catch (error) {
    console.error("Error logging out:", error);
  }
};
