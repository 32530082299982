import React from "react";
import { Box, Typography } from "@mui/material";
import {
  orange,
  green,
  red,
  teal,
  indigo,
  lime,
  pink,
  deepPurple,
} from "@mui/material/colors";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const renderNotificationIcon = (type, requiresApproval, isApproved) => {
  let iconClass = "fi fi-rr-bell"; // Default icon
  let iconColor = "inherit"; // Default icon color

  switch (type) {
    case "client_created":
      iconClass = "fi fi-rr-user-add";
      iconColor = teal[500];
      break;
    case "membership_creation":
      iconClass = "fi fi-rr-loyalty";
      break;
    case "membership_request":
      if (requiresApproval) {
        iconClass =
          isApproved === null
            ? "fi fi-rr-question-square"
            : isApproved
            ? "fi fi-rr-membership-vip"
            : "fi fi-rr-cross";
        iconColor =
          isApproved === null
            ? orange[500]
            : isApproved
            ? indigo[500]
            : red[500];
      }
      break;
    case "vouchers_request":
      if (requiresApproval) {
        iconClass =
          isApproved === null
            ? "fi fi-rr-question-square"
            : isApproved
            ? "fi fi-rr-ticket"
            : "fi fi-rr-cross";
        iconColor =
          isApproved === null ? orange[500] : isApproved ? lime[500] : red[500];
      }
      break;
    case "arrival_request":
      if (requiresApproval) {
        iconClass =
          isApproved === null
            ? "fi fi-rr-question-square"
            : isApproved
            ? "fi fi-rr-marker-time"
            : "fi fi-rr-cross";
        iconColor =
          isApproved === null ? orange[500] : isApproved ? pink[500] : red[500];
      }
      break;
    case "vouchers_creation":
      iconClass = "fi fi-rr-loyalty";
      break;
    case "appointment_services_updated":
      iconClass = "fi fi-rr-calendar-clock";
      iconColor = deepPurple[500];
      break;
    // Add more cases as necessary
    default:
      iconClass = "fi fi-rr-bell";
      break;
  }

  return (
    <i className={iconClass} style={{ color: iconColor, fontSize: "24px" }}></i>
  );
};

export const getApprovalIndicator = (isApproved) => {
  return isApproved === true ? (
    <Box display="flex" alignItems="center">
      <i
        className="fi fi-rr-check"
        style={{ color: green[500], marginRight: 4 }}
      ></i>
      <Typography variant="body2" style={{ color: green[600] }}>
        Approved
      </Typography>
    </Box>
  ) : isApproved === false ? (
    <Box display="flex" alignItems="center">
      <i
        className="fi fi-rr-cross"
        style={{ color: red[400], marginRight: 4 }}
      ></i>
      <Typography variant="body2" style={{ color: red[600] }}>
        Rejected
      </Typography>
    </Box>
  ) : null;
};

export const formatTimeAgo = (date) => {
  return dayjs(date).fromNow();
};
