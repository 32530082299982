import React, { useContext } from "react";
import { useRBACData } from "../../../context/RBACContext";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { createClient } from "../../../services/api/clients/clients";
import { Button, Box, Grid, Typography, CircularProgress } from "@mui/material";
import { Formik, Form } from "formik";
import { MyTextField } from "../../form/FormComponents";
import CustomDatePicker from "../../form/CustomDatePicker";
import * as Yup from "yup";
import FadeTransition from "../../effects/FadeTransition";
import LoadingBackdropCirc from "../../loading/LoadingBackdropCirc";
import dayjs from "dayjs";

// Form validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .test(
      "len",
      "Phone number must start with 0 and be 11 digits long",
      (val) => val && val.startsWith("0") && val.length === 11
    ),
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Invalid email format"
    ),
  date_of_birth: Yup.date().required("Date of birth is required"),
  occupation: Yup.string().required("Occupation is required"),
});

function CreateClient() {
  const { openSnackbar } = useContext(SnackbarContext);
  const { mutators } = useRBACData();
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    date_of_birth: null,
    occupation: "",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const formattedValues = {
      ...values,
      date_of_birth: values.date_of_birth
        ? dayjs(values.date_of_birth).format("YYYY-MM-DD")
        : null,
    };

    try {
      const response = await createClient(formattedValues);

      if (response && response.status === 201) {
        const newClient = response.data;

        mutators?.mutateClients((currentClients) => {
          if (currentClients && Array.isArray(currentClients.results)) {
            return {
              ...currentClients,
              results: [...currentClients.results, newClient],
            };
          } else {
            console.warn(
              "Expected prevData to have a results array, received:",
              currentClients
            );
            return {
              ...currentClients,
              results: [newClient],
            };
          }
        }, false);

        resetForm();
        openSnackbar("success", "Client created successfully!");
      } else {
        // Handle unexpected status codes that don't throw an error
        console.error(
          `Unexpected response status while creating: ${response?.status}`
        );
        openSnackbar(
          "error",
          `Unexpected server response: ${response?.status}. Please try again later.`
        );
      }
    } catch (error) {
      console.error("Error during client creation", error);

      // Default error message
      let errorMessage = "Failed to create client, please try again.";

      // Check if the error response has data and that data is not empty
      if (
        error.response &&
        error.response.data &&
        Object.keys(error.response.data).length > 0
      ) {
        // Extract the first error message from the first field with an error
        const [firstErrorField] = Object.keys(error.response.data);
        const firstErrorMessage = error.response.data[firstErrorField][0]; // Assuming each field error is an array
        errorMessage = firstErrorMessage;
      }

      openSnackbar("error", errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  if (!mutators?.mutateClients) {
    return <LoadingBackdropCirc open={true} />;
  }

  return (
    <FadeTransition in={true}>
      <Typography
        align="center"
        variant="h6"
        fontWeight={"medium"}
        sx={{ px: 2, pt: 2 }}
      >
        New Client
      </Typography>

      <Grid p={2}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, setFieldValue, values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <MyTextField name="name" label="Name" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomDatePicker
                    name="date_of_birth"
                    label="Date of Birth"
                    date={values.date_of_birth}
                    setDate={(newValue) =>
                      setFieldValue("date_of_birth", newValue)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyTextField name="phone" label="Phone" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyTextField name="email" label="Email" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MyTextField name="occupation" label="Occupation" />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting}
                  sx={{ my: 1 }}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Create Client"
                  )}
                </Button>
              </Box>
              <Box mt={2}>
                <Typography variant="body2" color="error">
                  {Object.keys(errors).length > 0 && (
                    <pre>{JSON.stringify(errors, null, 2)}</pre>
                  )}
                </Typography>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </FadeTransition>
  );
}

export default CreateClient;
