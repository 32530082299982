import { actionTypes } from "../statics/actionTypes";

export const notificationsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_INITIAL:
      return { ...state, notifications: action.payload.notifications };
    case actionTypes.FETCH_OLDER:
      const newNotifications = action.payload.notifications;
      const existingIds = new Set(state.notifications.map((notif) => notif.id));
      const filteredNotifications = newNotifications.filter(
        (notif) => !existingIds.has(notif.id)
      );
      return {
        ...state,
        notifications: [...state.notifications, ...filteredNotifications],
        hasMore: newNotifications.length > 0, // Update based on whether new notifications were fetched
      };
    case actionTypes.ADD_NEW:
      return {
        ...state,
        notifications: [action.payload.notification, ...state.notifications],
      };
    case actionTypes.MARK_AS_READ:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification.id === action.payload.notificationId
            ? { ...notification, is_read: true }
            : notification
        ),
      };
    case actionTypes.MARK_AS_UNREAD:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification.id === action.payload.notificationId
            ? { ...notification, is_read: false }
            : notification
        ),
      };
    case actionTypes.MARK_ALL_AS_READ:
      return {
        ...state,
        notifications: state.notifications.map((notification) => ({
          ...notification,
          is_read: true,
        })),
      };
    case actionTypes.APPROVE:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification.id === action.payload.notificationId
            ? { ...notification, is_approved: action.payload.approved }
            : notification
        ),
      };

    case actionTypes.REMOVE:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload.notificationId
        ),
      };
    case actionTypes.START_LOADING:
      return { ...state, isLoading: true, isError: false };
    case actionTypes.LOADING_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.LOADING_FAILURE:
      return { ...state, isLoading: false, isError: true };
  }
};
