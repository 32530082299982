import React, { useContext, useState, useEffect } from "react";
import { SnackbarContext } from "../../../context/SnackbarContext";
import {
  Box,
  useTheme,
  TextField,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DialogWrapper from "../../dialog/DialogWrapper";
import { searchClientByPhoneOrName } from "../../../services/api/clients/clients";
import { useNavigate } from "react-router-dom";
import FadeTransition from "../../effects/FadeTransition";

function ClientLookup({ isSmallScreen, onSearchToggle }) {
  const { openSnackbar } = useContext(SnackbarContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [shrinkInput, setShrinkInput] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    onSearchToggle(focused);
  }, [focused, onSearchToggle]);

  const handleSearch = async () => {
    try {
      if (!searchTerm.trim()) {
        openSnackbar(
          "warning",
          "Please enter a name or phone number to search."
        );
        return;
      }

      const response = await searchClientByPhoneOrName(searchTerm.trim());

      if (Array.isArray(response)) {
        setSearchResults(response);
        setIsDialogOpen(true);
      } else if (response && response.id) {
        navigate(`/clients/${response.id}`);
      }
    } catch (error) {
      let message =
        error.message || "An error occurred while searching for the client!";
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message = error.response.data.message;
      }

      console.error(error);
      openSnackbar("error", message);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleFocus = () => {
    setFocused(true);
    setShrinkInput(false);
  };

  const handleBlur = () => {
    setFocused(false);
    setTimeout(() => setShrinkInput(true), 300); // Add delay for input shrink
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleListItemClick = (clientId) => {
    navigate(`/clients/${clientId}`);
    handleCloseDialog();
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <FadeTransition in={true}>
        <TextField
          fullWidth
          size="small"
          label="Search Client by Phone or Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={handleKeyPress}
          onFocus={handleFocus}
          onBlur={handleBlur}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            style: { borderRadius: 12, backgroundColor: "white" },
          }}
          sx={{
            transition: theme.transitions.create(["width"], {
              duration: shrinkInput
                ? theme.transitions.duration.shortest
                : theme.transitions.duration.standard,
            }),
            width: focused
              ? isSmallScreen
                ? "calc(100% - 40px)"
                : "250px"
              : "200px",
          }}
        />
      </FadeTransition>
      <DialogWrapper
        open={isDialogOpen}
        onClose={handleCloseDialog}
        title="Search Results"
      >
        <List>
          {searchResults.map((client) => (
            <ListItem
              button
              onClick={() => handleListItemClick(client.id)}
              key={client.id}
            >
              <ListItemText
                primary={client.name}
                secondary={`Phone: ${client.phone}`}
              />
            </ListItem>
          ))}
        </List>
      </DialogWrapper>
    </Box>
  );
}

export default ClientLookup;
