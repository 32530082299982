import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";

function DialogWrapper({
  open,
  onClose,
  title,
  children,
  maxWidth = "lg",
  fullWidth = false,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <DialogTitle
        align="center"
        variant="h6"
        fontWeight={"medium"}
        sx={{ px: 2, pt: 2 }}
      >
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default DialogWrapper;
