import React, { useState, useEffect, createContext, useCallback } from "react";
import { getMyData } from "../services/api/users/users";
import { useAuthNavigation } from "../hooks/navigation/useAuthNavigation";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);

  useAuthNavigation(userDetails, isLoading);

  useEffect(() => {
    const isUserInGroup = (groupName) => {
      return userDetails?.groups?.includes(groupName);
    };

    setIsAdmin(isUserInGroup("Admin"));
    setIsManager(isUserInGroup("Manager"));
  }, [userDetails]);

  const fetchUserData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getMyData();
      if (response.success) {
        setUserDetails({
          userId: response.data.id,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          groups: response.data.groups,
          userName: response.data.username,
          avatar: response.data.avatar,
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      clearUserDetails();
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!userDetails) {
      fetchUserData();
    }
  }, [userDetails, fetchUserData]);

  const handleLogin = () => {
    fetchUserData();
  };

  const clearUserDetails = () => {
    setUserDetails(null);
  };

  return (
    <AuthContext.Provider
      value={{
        userDetails,
        isAuthenticated: !!userDetails,
        handleLogin,
        clearUserDetails,
        isLoading,
        isAdmin,
        isManager,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
