import dayjs from "dayjs";

export const currencyFormatter = new Intl.NumberFormat("en-PH", {
  style: "currency",
  currency: "PHP",
});

export const intCurrencyFormatter = (value) => {
  // Round the value to the nearest whole number
  const roundedValue = Math.round(value);

  // Format the number as currency without decimals
  return new Intl.NumberFormat("en-PH", {
    style: "currency",
    currency: "PHP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(roundedValue);
};

export const phpPrice = {
  type: "number",
  valueFormatter: ({ value }) => currencyFormatter.format(value),
  cellClassName: "font-tabular-nums",
};

export const generateTimeOptions = (
  startHour = 9,
  endHour = 22,
  intervalMinutes = 15
) => {
  const times = [];
  let currentTime = dayjs().hour(startHour).minute(0).second(0);
  const endTime = dayjs().hour(endHour).minute(0).second(0);

  while (currentTime.isBefore(endTime)) {
    times.push(currentTime.format("HH:mm"));
    currentTime = currentTime.add(intervalMinutes, "minute");
  }

  return times;
};

export function roundToNearest15Min(date) {
  const minutes = 15 * Math.round(date.minute() / 15);
  return date.clone().minute(minutes).second(0);
}

export function ordinalSuffixOf(i) {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

export const hoursDurationFormatter = (minutes) => {
  if (minutes < 60) {
    return `${minutes} mins`;
  } else {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, "0")} hours`;
  }
};
