import React from "react";
import {
  Typography,
  Link,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const MembershipRequestNotification = ({ notification, isAdmin }) => {
  const {
    client_id,
    client_name,
    membership_name,
    selected_categories,
    selected_treatments,
    start_date,
  } = notification.data.request_data;
  const navigate = useNavigate();

  // Group treatments by category for display
  const treatmentsByCategory = selected_treatments.reduce((acc, treatment) => {
    acc[treatment.category] = acc[treatment.category] || [];
    acc[treatment.category].push(treatment.name);
    return acc;
  }, {});

  return (
    <>
      <Typography variant="body2">{notification.message}</Typography>
      <Divider sx={{ my: 1 }} />
      <Typography variant="h5">{membership_name} Membership</Typography>
      <Typography variant="caption">
        Starting date {new Date(start_date).toLocaleDateString()}.
      </Typography>
      <Grid container spacing={2}>
        {selected_categories.map((category) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={category.id}>
            <Box>
              <Typography variant="subtitle1">
                {category.quantity} {category.name}s
              </Typography>
              <List dense>
                {treatmentsByCategory[category.name]?.map(
                  (treatment, index) => (
                    <ListItem key={index}>
                      <ListItemText secondary={` - ${treatment}`} />
                    </ListItem>
                  )
                )}
              </List>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Link
        component="button"
        variant="subtitle1"
        onClick={() =>
          navigate(
            isAdmin ? `/admin/clients/${client_id}` : `/clients/${client_id}`
          )
        }
        underline="none"
      >
        Go to {client_name} profile
      </Link>
    </>
  );
};

export default MembershipRequestNotification;
