import React from "react";
import { isFeatureEnabled } from "../../utils/featureFlags";

export const adminItems = [
  ...(isFeatureEnabled("admin_dashboard")
    ? [
        {
          icon: <i className="fi fi-sr-dashboard"></i>,
          OutlineIcon: <i className="fi fi-rr-dashboard"></i>,
          label: "Admin Dashboard",
          path: "/admin/dashboard",
        },
      ]
    : []),

  ...(isFeatureEnabled("users")
    ? [
        {
          icon: <i className="fi fi-sr-user"></i>,
          OutlineIcon: <i className="fi fi-rr-user"></i>,
          label: "Users",
          path: "/admin/users",
        },
      ]
    : []),
  {
    icon: <i className="fi fi-sr-briefcase"></i>,
    OutlineIcon: <i className="fi fi-rr-briefcase"></i>,
    label: "Employees",
    path: "/admin/employees",
  },
  {
    icon: <i className="fi fi-sr-users"></i>,
    OutlineIcon: <i className="fi fi-rr-users"></i>,
    label: "Clients",
    path: "/admin/clients",
  },
  {
    icon: <i className="fi fi-sr-store-alt"></i>,
    OutlineIcon: <i className="fi fi-rr-store-alt"></i>,
    label: "Locations",
    path: "/admin/locations",
  },
  {
    icon: <i className="fi fi-ss-massage"></i>,
    OutlineIcon: <i className="fi fi-rs-massage"></i>,
    label: "Services",
    path: "/admin/services",
  },
  ...(isFeatureEnabled("banks")
    ? [
        {
          icon: <i className="fi fi-sr-bank"></i>,
          OutlineIcon: <i className="fi fi-rr-bank"></i>,
          label: "Banks",
          path: "/admin/banks",
        },
      ]
    : []),
  ...(isFeatureEnabled("reports")
    ? [
        {
          icon: <i className="fi fi-sr-file"></i>,
          OutlineIcon: <i className="fi fi-rr-file"></i>,
          label: "Reports",
          path: "/admin/reports",
        },
      ]
    : []),
  {
    icon: <i className="fi fi-sr-settings"></i>,
    OutlineIcon: <i className="fi fi-rr-settings"></i>,
    label: "Settings",
    path: "/admin/settings",
  },
];

export const userItems = [
  // {
  //   value: "/home",
  //   label: "Home",
  //   icon: <i class="fi fi-sr-house-chimney"></i>,
  //   OutlineIcon: <i class="fi fi-rr-house-chimney"></i>,
  //   isAdmin: false,
  // },
  {
    value: "/calendar",
    label: "Calendar",
    icon: <i className="fi fi-sr-calendar"></i>,
    OutlineIcon: <i className="fi fi-rr-calendar"></i>,
    isAdmin: false,
  },
];

export const managerItems = [
  {
    path: "/manager-panel",
    label: "Manager Panel",
    icon: <i className="fi fi-sr-user-gear"></i>,
    OutlineIcon: <i className="fi fi-rr-user-gear"></i>,
    isAdmin: false,
    isManager: true,
  },
];

export const mobileItems = [
  // {
  //   value: "/home",
  //   label: "Home",
  //   icon: <i class="fi fi-sr-house-chimney"></i>,
  //   OutlineIcon: <i class="fi fi-rr-house-chimney"></i>,
  //   isAdmin: false,
  // },
  {
    value: "/calendar",
    label: "Calendar",
    icon: <i className="fi fi-sr-calendar"></i>,
    OutlineIcon: <i className="fi fi-rr-calendar"></i>,
    isAdmin: false,
  },
];
