//NotificationDetails.js
import React, { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { Box, Typography } from "@mui/material";
import ClientCreatedNotification from "./types/ClientCreatedNotification";
import MembershipCreationNotification from "./types/MembershipCreationNotification";
import MembershipRequestNotification from "./types/MembershipRequestNotification";
import VouchersRequestNotification from "./types/VouchersRequestNotification";
import ArrivalRequestNotification from "./types/ArrivalRequestNotification";
import AppointmentUpdatedNotification from "./types/AppointmentUpdatedNotification";

const NotificationDetails = ({ notification }) => {
  const { isAdmin } = useContext(AuthContext);

  const getNotificationComponent = (type) => {
    const components = {
      client_created: ClientCreatedNotification,
      membership_creation: MembershipCreationNotification,
      membership_request: MembershipRequestNotification,
      vouchers_request: VouchersRequestNotification,
      arrival_request: ArrivalRequestNotification,
      appointment_services_updated: AppointmentUpdatedNotification,
    };
    return components[type] || null;
  };

  const NotificationComponent = getNotificationComponent(notification.type);

  return (
    <Box p={2}>
      {NotificationComponent ? (
        <NotificationComponent notification={notification} isAdmin={isAdmin} />
      ) : (
        <Typography variant="body2">Unknown notification type.</Typography>
      )}
    </Box>
  );
};

export default NotificationDetails;
