import React from "react";
import { Typography, Link, List, ListItem, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MembershipCreationNotification = ({ notification, isAdmin }) => {
  const { client_id, client_name, membership_details } = notification.data;
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="body2">
        {`Membership "${membership_details.membership_name}" created for client ${client_name}.`}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Services Included:
      </Typography>
      <List>
        {Object.entries(membership_details.services).map(
          ([category, { treatments, quantity }]) => (
            <ListItem key={category}>
              <ListItemText
                primary={`${quantity}x ${category}`}
                secondary={treatments.join(", ")}
              />
            </ListItem>
          )
        )}
      </List>
      <Link
        component="button"
        variant="subtitle1"
        onClick={() =>
          navigate(
            isAdmin ? `/admin/clients/${client_id}` : `/clients/${client_id}`
          )
        }
        underline="none"
      >
        View Membership Details
      </Link>
    </>
  );
};

export default MembershipCreationNotification;
