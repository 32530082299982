import React, { useState } from "react";
import { useNotifications } from "../../context/NotificationsContext";
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import {
  renderNotificationIcon,
  getApprovalIndicator,
  formatTimeAgo,
} from "./NotificationUtils";
import NotificationActions from "../notifications/NotificationActions";
import NotificationDetails from "./NotificationDetails";

const NotificationList = ({
  handlePopoverClose,
  handleNotificationClick,
  expanded,
  setExpanded,
}) => {
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [offset, setOffset] = useState(20);

  const {
    state: { notifications, hasMore },
    markAsRead,
    markAllAsRead,
    fetchOlder,
  } = useNotifications();

  const unreadNotificationsExist = notifications.some(
    (notif) => !notif.is_read
  );

  const loadMoreNotifications = () => {
    fetchOlder(offset);
    setOffset((prevOffset) => prevOffset + 20);
  };

  const renderLoadMoreButton = () => {
    if (!hasMore)
      return (
        <Typography
          textAlign={"center"}
          variant={"caption"}
          sx={{ margin: "auto", display: "block", mt: 2 }}
        >
          No more notifications
        </Typography>
      );
    return (
      <Button
        sx={{ border: "none", margin: "auto", display: "block", mt: 2 }}
        variant="outlined"
        onClick={loadMoreNotifications}
      >
        Load More
      </Button>
    );
  };

  const renderMarkAllAsReadButton = () => {
    if (!unreadNotificationsExist) return null;
    return (
      <Box display="flex" justifyContent="flex-end" p={1}>
        <Button color="secondary" onClick={markAllAsRead} size="small">
          Mark All as Read
        </Button>
      </Box>
    );
  };

  const now = new Date();
  const twoHoursAgo = new Date(now.getTime() - 2 * 60 * 60 * 1000);
  const startOfToday = new Date(now.setHours(0, 0, 0, 0));

  const newNotifications = notifications.filter(
    (notification) => new Date(notification.date) > twoHoursAgo
  );
  const todayNotifications = notifications.filter(
    (notification) =>
      new Date(notification.date) > startOfToday &&
      new Date(notification.date) <= twoHoursAgo
  );
  const earlierNotifications = notifications.filter(
    (notification) => new Date(notification.date) <= startOfToday
  );

  const handleClick = async (notification) => {
    if (!notification.is_read) {
      await markAsRead(notification.id);
    }
    if (selectedNotification && selectedNotification.id === notification.id) {
      setSelectedNotification(null);
      setExpanded(false);
    } else {
      setSelectedNotification(notification);
      handleNotificationClick();
    }
  };

  const renderNotificationsAccordion = (notifications, title) => (
    <>
      {notifications.length > 0 && (
        <Box mr={2}>
          <Typography
            component="div"
            variant="h6"
            color="text.secondary"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
              mb: 1,
              ml: 2,
            }}
          >
            {title}
          </Typography>
          {notifications.map((notification) => (
            <Accordion
              key={notification.id}
              expanded={
                selectedNotification &&
                selectedNotification.id === notification.id &&
                expanded
              }
              onChange={() => handleClick(notification)}
              sx={{
                "&:hover": {
                  backgroundColor: blue[50],
                },
                cursor: "pointer",
                boxShadow: "none", // Remove elevation
                border: "none", // Remove border
                "&:before": {
                  display: "none", // Remove dividing line
                },
                "& + &": {
                  marginTop: 0, // Remove margin between accordions
                },
              }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: "none", // Remove border from summary
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {renderNotificationIcon(
                    notification.type,
                    notification.requires_approval,
                    notification.is_approved
                  )}
                  <Box sx={{ ml: 2 }}>
                    <Typography component="div" variant="body2">
                      {notification.message}
                      {getApprovalIndicator(notification.is_approved)}
                      <Badge
                        color="secondary"
                        variant="dot"
                        invisible={notification.is_read}
                        sx={{ ml: 1 }}
                      />
                    </Typography>
                    <Typography
                      component="div"
                      variant="caption"
                      color="textSecondary"
                    >
                      {formatTimeAgo(notification.date)}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {selectedNotification &&
                  selectedNotification.id === notification.id && (
                    <>
                      <NotificationDetails
                        notification={selectedNotification}
                      />
                      <NotificationActions
                        notification={selectedNotification}
                        setDrawerOpen={() => setSelectedNotification(null)}
                      />
                      <Typography
                        sx={{ mt: 2, textAlign: "right" }}
                        variant="subtitle1"
                        color="text.secondary"
                      >
                        #{selectedNotification.id}
                      </Typography>
                    </>
                  )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </>
  );

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <Typography component="div" variant="h5">
          Notifications
        </Typography>
        {renderMarkAllAsReadButton()}
      </Box>
      {renderNotificationsAccordion(newNotifications, "New")}
      {renderNotificationsAccordion(todayNotifications, "Today")}
      {renderNotificationsAccordion(earlierNotifications, "Earlier")}
      {renderLoadMoreButton()}
      {newNotifications.length === 0 &&
        todayNotifications.length === 0 &&
        earlierNotifications.length === 0 && (
          <Typography
            component="div"
            sx={{ mt: 2, mb: 2, ml: 2 }}
            variant="subtitle1"
          >
            No notifications to show at the moment.
          </Typography>
        )}
    </Box>
  );
};

export default NotificationList;
