// context/RBACContext.js
import React, { createContext, useContext } from "react";
import { AuthContext } from "./authContext";
import useFetchAdminData from "../hooks/rbac/useFetchAdminData";
import useFetchUserData from "../hooks/rbac/useFetchUserData";

const RBACContext = createContext();

export const RBACProvider = ({ children }) => {
  const { isAdmin, isAuthenticated } = useContext(AuthContext);

  // Use the appropriate hook based on the user's role
  const adminData = useFetchAdminData(isAdmin);
  const userData = useFetchUserData(isAuthenticated);

  // Choose which set of data to provide based on the user's role
  const value = isAdmin ? adminData : userData;

  //   console.log(adminData);
  // console.log(userData);

  return <RBACContext.Provider value={value}>{children}</RBACContext.Provider>;
};

export const useRBACData = () => useContext(RBACContext);
