//clients
import axiosInstance from "../../apiService";
import { CLIENT_API_ENDPOINTS } from "../../apiConstants";

export const getClients = async (page = 1, pageSize = 100) => {
  const params = { page, page_size: pageSize };
  const response = await axiosInstance.get(CLIENT_API_ENDPOINTS.CLIENTS, {
    params,
  });
  return response.data;
};

export const getClientById = async (clientId) => {
  const response = await axiosInstance.get(
    CLIENT_API_ENDPOINTS.CLIENT(clientId)
  );
  return response.data;
};

export const searchClientByPhoneOrName = async (searchTerm) => {
  const isPhoneNumber = /^\d+$/.test(searchTerm);
  const params = isPhoneNumber ? { phone: searchTerm } : { name: searchTerm };
  try {
    const response = await axiosInstance.get(
      CLIENT_API_ENDPOINTS.CLIENT_SEARCH,
      {
        params,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error searching for client:", error);
    throw error;
  }
};

export const clientDataByPhoneOrName = async (searchTerm) => {
  const isPhoneNumber = /^\d+$/.test(searchTerm);
  const params = isPhoneNumber ? { phone: searchTerm } : { name: searchTerm };
  try {
    const response = await axiosInstance.get(
      CLIENT_API_ENDPOINTS.CLIENT_DETAILED_SEARCH,
      {
        params,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error searching for client:", error);
    throw error;
  }
};

export const createClient = (data) =>
  axiosInstance.post(CLIENT_API_ENDPOINTS.CLIENTS, data);

export const editClient = (clientId, data) =>
  axiosInstance.put(CLIENT_API_ENDPOINTS.CLIENT(clientId), data);

export const deleteClient = (clientId) =>
  axiosInstance.delete(CLIENT_API_ENDPOINTS.CLIENT(clientId));
