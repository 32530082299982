import React, { Suspense, lazy } from "react";
import Layout from "../components/Layout";
import { Routes, Route, Navigate } from "react-router-dom";
import { ProtectedRoute } from "../components/protectedroute/ProtectedRoute";
import LoadingBackdropCirc from "../components/loading/LoadingBackdropCirc";
import { ScheduleFormProvider } from "../context/ScheduleFormContext";
import MenuList from "../components/menu/MenuList";

// const Dashboard = lazy(() => import("../pages/user/Dashboard"));
const Calendar = lazy(() => import("../pages/user/Calendar"));
const Profile = lazy(() => import("../pages/user/Profile"));
const ClientPage = lazy(() => import("../pages/user/ClientPage"));
const AdminRoutes = lazy(() => import("../components/admin/AdminRoutes"));
const Notifications = lazy(() =>
  import("../pages/notifications/Notifications")
);
const ManagerPanel = lazy(() => import("../pages/user/ManagerPanel"));

export default function PrivateRoutes() {
  return (
    <Suspense fallback={<LoadingBackdropCirc open={true} />}>
      <Layout>
        <Routes>
          {/* <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/mobile-menu"
            element={
              <ProtectedRoute>
                <MenuList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />
          <Route
            path="/calendar"
            element={
              <ProtectedRoute>
                <ScheduleFormProvider>
                  <Calendar />
                </ScheduleFormProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/manager-panel"
            element={
              <ProtectedRoute managerOnly={true}>
                <ManagerPanel />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients/:clientId"
            element={
              <ProtectedRoute>
                <ClientPage isAdmin={false} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute adminOnly={true}>
                <AdminRoutes />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </Layout>
    </Suspense>
  );
}
