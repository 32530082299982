import React, { useContext, useState } from "react";
import {
  useTheme,
  Tab,
  Tabs,
  useMediaQuery,
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  CircularProgress,
  Tooltip,
  Box,
  Avatar,
  Popover,
  capitalize,
  Divider,
  Slide,
  useScrollTrigger,
  Fade,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import AddIcon from "@mui/icons-material/Add";
import { AuthContext } from "../../context/authContext";
import { useNotifications } from "../../context/NotificationsContext";
import UserPopover from "./UserPopover";
import MenuList from "./MenuList";
import ClientLookup from "../user/clients/ClientLookup";
import CreateClient from "../user/clients/CreateClient";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";

function TopNav() {
  const { userDetails, isAdmin, isManager } = useContext(AuthContext);
  const {
    state: { notifications },
    isLoading,
    isError,
  } = useNotifications();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [appsAnchorEl, setAppsAnchorEl] = useState(null);
  const [clientAnchorEl, setClientAnchorEl] = useState(null);
  const [searchExpanded, setSearchExpanded] = useState(false);

  const setAllPopoversClosed = () => {
    setAnchorEl(null);
    setAppsAnchorEl(null);
    setClientAnchorEl(null);
  };

  const handleNavigation = (event, path) => {
    setAllPopoversClosed();
    navigate(path);
  };

  const isActive = (path) => location.pathname === path;

  const renderErrorIcon = () => (
    <Tooltip title="Error loading notifications">
      <ErrorIcon color="error" />
    </Tooltip>
  );

  const renderBadgeContent = () => {
    if (isLoading) return <CircularProgress size={24} color="inherit" />;
    if (isError) return renderErrorIcon();
    return (notifications || []).filter((notification) => !notification.is_read)
      .length;
  };

  const unreadCount = renderBadgeContent();

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleAppsClick = (event) => {
    setAppsAnchorEl(event.currentTarget);
  };

  const handleAppsPopoverClose = () => {
    setAppsAnchorEl(null);
  };

  const handleClientClick = (event) => {
    setClientAnchorEl(event.currentTarget);
  };

  const handleClientPopoverClose = () => {
    setClientAnchorEl(null);
  };

  const handleSearchToggle = (expanded) => {
    setSearchExpanded(expanded);
  };

  const trigger = useScrollTrigger();

  return (
    <>
      <Slide appear={false} direction="down" in={!trigger || !isSmallScreen}>
        <AppBar
          position={isSmallScreen ? "fixed" : "sticky"}
          sx={{
            top: 0,
            zIndex: theme.zIndex.appBar,
            backgroundColor: "white",
            boxShadow: "none",
            color: theme.palette.text.primary,
          }}
        >
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <Fade in={!searchExpanded}>
                <Avatar
                  sx={{
                    display: searchExpanded ? "none" : "inline-block",
                    mr: 2,
                    width: 48,
                    height: 48,
                  }}
                  src={theme.logo}
                  alt="Logo"
                />
              </Fade>
              <Slide
                direction="down"
                in={searchExpanded}
                mountOnEnter
                unmountOnExit
              >
                <IconButton
                  onClick={() => handleSearchToggle(false)}
                  sx={{
                    position: "absolute",
                    left: theme.spacing(2),
                    top: theme.spacing(1),
                    zIndex: theme.zIndex.appBar + 1,
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Slide>
              {!isSmallScreen && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    ml: searchExpanded ? 5 : 0,
                    transition: theme.transitions.create(["margin"], {
                      duration: theme.transitions.duration.standard,
                    }),
                  }}
                >
                  <ClientLookup onSearchToggle={handleSearchToggle} />
                  <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                  <Tooltip title="New Client">
                    <IconButton
                      variant="secondary"
                      color="primary"
                      onClick={handleClientClick}
                    >
                      <i className="fi fi-rr-square-plus"></i>
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
            {!isSmallScreen && (
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Tabs
                  value={
                    isActive("/home")
                      ? "/home"
                      : isActive("/calendar")
                      ? "/calendar"
                      : false
                  }
                  onChange={handleNavigation}
                  aria-label="navigation tabs"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab
                    label="Calendar"
                    value="/calendar"
                    icon={
                      isActive("/calendar") ? (
                        <i className="fi fi-rr-calendar"></i>
                      ) : (
                        <i className="fi fi-sr-calendar"></i>
                      )
                    }
                    sx={{ mx: 2 }}
                  />
                </Tabs>
              </Box>
            )}
            {!isSmallScreen && (isAdmin || isManager) && (
              <IconButton
                edge="end"
                color="inherit"
                aria-label="apps"
                sx={{
                  ml: 2,
                  borderRadius: "50%",
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
                onClick={handleAppsClick}
              >
                <i className="fi fi-rr-apps"></i>
              </IconButton>
            )}
            {!isSmallScreen && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  ml: 2,
                }}
              >
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  badgeContent={unreadCount}
                  color="error"
                >
                  <Avatar
                    alt={`${capitalize(userDetails?.firstName)} ${capitalize(
                      userDetails?.lastName
                    )}`}
                    src={userDetails.avatar}
                    sx={{ width: 32, height: 32, cursor: "pointer" }}
                    onClick={handleAvatarClick}
                  />
                </Badge>
              </Box>
            )}
            {isSmallScreen && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ClientLookup onSearchToggle={handleSearchToggle} />
                <IconButton
                  aria-label="add"
                  color="inherit"
                  onClick={handleClientClick}
                >
                  <i className="fi fi-rr-square-plus"></i>
                </IconButton>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </Slide>
      <UserPopover anchorEl={anchorEl} handleClose={handlePopoverClose} />
      <Popover
        open={Boolean(appsAnchorEl)}
        anchorEl={appsAnchorEl}
        onClose={handleAppsPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            width: 450,
            boxShadow:
              "0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1)",
            borderRadius: 2,
          },
        }}
      >
        <MenuList handleClose={handleAppsPopoverClose} />
      </Popover>
      <Popover
        open={Boolean(clientAnchorEl)}
        anchorEl={clientAnchorEl}
        onClose={handleClientPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            width: 350,
            padding: 2,
            boxShadow:
              "0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1)",
            borderRadius: 2,
          },
        }}
      >
        <CreateClient />
      </Popover>
    </>
  );
}

export default TopNav;
