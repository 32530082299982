import React from "react";
import BottomNav from "./menu/BottomNav";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TopNav from "./menu/TopNav";

const Layout = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <TopNav />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          paddingTop: !isDesktop ? "56px" : "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "auto",
            overflow: "hidden",
          }}
        >
          <Box
            component="main"
            sx={{ flexGrow: 1, overflow: "auto", padding: 1 }}
          >
            {children}
          </Box>
        </Box>
      </Box>
      {!isDesktop && <BottomNav />}
    </>
  );
};

export default Layout;
