//apiService.js
import axios from "axios";
import { redirectToLogin } from "./redirectService";
import { getCookie } from "./cookieService";
import { BASE_URL } from "./apiConstants";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // Important for session cookies
});

axiosInstance.interceptors.request.use((config) => {
  const csrfToken = getCookie("csrftoken"); // Get CSRF token from cookie
  if (csrfToken) {
    config.headers["X-CSRFToken"] = csrfToken; // Set CSRF token in header
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      return Promise.reject(
        new Error("Network error or request was cancelled")
      );
    }

    // Redirect to login on 401 Unauthorized
    if (error.response.status === 401) {
      redirectToLogin();
    }

    const errorMessage =
      error.response.data.message || "An unexpected error occurred";
    return Promise.reject(new Error(errorMessage));
  }
);

export default axiosInstance;
