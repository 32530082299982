// FormComponents.js
import React from "react";
import {
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
} from "@mui/material";
import { useField } from "formik";

export const MyTextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const error = meta.touched && !!meta.error;
  return (
    <TextField
      {...field}
      {...props}
      label={label}
      fullWidth
      margin="normal"
      error={error}
      helperText={error ? meta.error : ""}
      size="small"
      sx={{ my: 1 }}
    />
  );
};

export const MySelectField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const hasError = meta.touched && !!meta.error;
  return (
    <FormControl size="small" fullWidth sx={{ my: 1 }} error={hasError}>
      <InputLabel>{label}</InputLabel>
      <Select {...field} {...props}>
        {props.children}
      </Select>
      {hasError ? (
        <Typography
          variant="body2"
          color="error"
          sx={{
            fontSize: "0.75rem", // smaller font-size
            ml: 2,
            mt: 0.5,
          }}
        >
          {meta.error}
        </Typography>
      ) : null}
    </FormControl>
  );
};

export const MyRadioGroup = ({ label, ...props }) => {
  const [field, , helpers] = useField(props);
  const { setValue } = helpers;
  return (
    <FormControl component="fieldset" fullWidth sx={{ my: 1 }}>
      <Typography component="legend">{label}</Typography>
      <RadioGroup
        {...field}
        {...props}
        onChange={(event) => setValue(event.target.value === "true")}
      >
        <FormControlLabel value="false" control={<Radio />} label="Local" />
        <FormControlLabel value="true" control={<Radio />} label="Foreign" />
      </RadioGroup>
    </FormControl>
  );
};

export const SelectWithChips = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <FormControl size="small" fullWidth sx={{ my: 1 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        {...props}
        multiple
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((role, index) => (
              <Chip size="small" key={index} label={role} />
            ))}
          </Box>
        )}
      >
        {props.children}
      </Select>
      {meta.touched && meta.error ? <Box color="red">{meta.error}</Box> : null}
    </FormControl>
  );
};

export const customFilter = (options, { inputValue }, getOptionLabel) => {
  const inputWords = inputValue.split(" ").filter((word) => word.length > 0);
  return options.filter((option) => {
    const label = getOptionLabel(option);
    const optionWords = label.split(" ");
    return inputWords.every((inputWord) =>
      optionWords.some((optionWord) =>
        optionWord.toLowerCase().includes(inputWord.toLowerCase())
      )
    );
  });
};

export const MyAutocomplete = ({
  label,
  options,
  getOptionLabel,
  onChange,
  valueSelector = (option) => option,
  isValueAnObject = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const error = meta.touched && !!meta.error;
  const { setValue, setTouched } = helpers;

  const handleChange = (event, newValue) => {
    setValue(valueSelector(newValue));

    if (onChange) {
      onChange(event, newValue);
    }
  };

  const findValueInOptions = (value) => {
    if (!value) return null;
    if (isValueAnObject) {
      return value;
    } else {
      return options.find((option) => valueSelector(option) === value) || null;
    }
  };

  return (
    <Autocomplete
      {...field}
      {...props}
      size="small"
      options={options}
      getOptionLabel={getOptionLabel}
      value={findValueInOptions(field.value)}
      onChange={handleChange}
      onBlur={() => setTouched(true)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={error}
          helperText={error ? meta.error.toString() : ""}
        />
      )}
    />
  );
};
