//NotificationActions.js
import React, { useContext, useState } from "react";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Grid,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNotifications } from "../../context/NotificationsContext";
import { AuthContext } from "../../context/authContext";

const NotificationActions = ({ notification, setDrawerOpen }) => {
  const { isAdmin } = useContext(AuthContext);
  const { markAsUnread, removeNotification, approveNotification } =
    useNotifications();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAsUnread = async () => {
    await markAsUnread(notification.id);
    handleClose();
  };

  const handleDeleteNotification = async () => {
    await removeNotification(notification.id);
    handleClose();
    setDrawerOpen(false);
  };

  const approveRequest = async () => {
    await approveNotification(notification.id, true);
    handleClose();
    setDrawerOpen(false);
  };

  const rejectRequest = async () => {
    await approveNotification(notification.id, false);
    handleClose();
    setDrawerOpen(false);
  };

  return (
    <Grid sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
      <Grid item xs={11}>
        {notification.requires_approval &&
          notification.is_approved === null && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="success"
                  variant="contained"
                  onClick={approveRequest}
                >
                  Approve
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="error"
                  variant="contained"
                  onClick={rejectRequest}
                >
                  Reject
                </Button>
              </Grid>
            </Grid>
          )}
      </Grid>
      <Grid item xs={1}>
        <Tooltip title="More actions">
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleMarkAsUnread}>Mark as Unread</MenuItem>
          {isAdmin ? (
            <MenuItem onClick={handleDeleteNotification}>Remove</MenuItem>
          ) : (
            ""
          )}
        </Menu>
      </Grid>
    </Grid>
  );
};

export default NotificationActions;
