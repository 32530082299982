// users.js
import axiosInstance from "../../apiService";
import {
  SALESPERSON_API_ENDPOINTS,
  USER_API_ENDPOINTS,
} from "../../apiConstants";

export const getUsers = async () => {
  const response = await axiosInstance.get(USER_API_ENDPOINTS.USERS);
  return response.data;
};

export const getMyData = async () => {
  try {
    const response = await axiosInstance.get(USER_API_ENDPOINTS.MY_DATA);
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Error fetching current user:", error);
    return { success: false, error: error };
  }
};

export const getUser = async (userId) => {
  try {
    const response = await axiosInstance.get(USER_API_ENDPOINTS.USER(userId));
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Error fetching current user:", error);
    return { success: false, error: error };
  }
};

export const getUserSalesperson = async (userId) => {
  const response = await axiosInstance.get(
    SALESPERSON_API_ENDPOINTS.SALESPERSON_BY_USER_ID(userId)
  );
  return response.data;
};

export const createUser = (data) =>
  axiosInstance.post(USER_API_ENDPOINTS.USERS, data);

export const editUser = (userId, data) =>
  axiosInstance.put(USER_API_ENDPOINTS.USER(userId), data);

export const editUserPassword = (userId, passwordData) =>
  axiosInstance.post(
    USER_API_ENDPOINTS.UPDATE_USER_PASSWORD(userId),
    passwordData
  );

export const deleteUser = (userId) =>
  axiosInstance.delete(USER_API_ENDPOINTS.USER(userId));
