import { createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

const defaultFontFamily = [
  "Roboto",
  "Arial",
  "sans-serif",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  '"Helvetica Neue"',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(",");

const headingsFontFamily = [
  "Proxima Nova",
  "Roboto",
  "Arial",
  "sans-serif",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  '"Helvetica Neue"',
].join(",");

const theme = createTheme({
  typography: {
    fontFamily: defaultFontFamily,
    h1: {
      fontFamily: headingsFontFamily,
      fontWeight: 500,
      fontSize: "2.25rem",
    },
    h2: { fontFamily: headingsFontFamily, fontWeight: 500, fontSize: "2rem" },
    h3: {
      fontFamily: headingsFontFamily,
      fontWeight: 500,
      fontSize: "1.75rem",
    },
    h4: { fontFamily: headingsFontFamily, fontWeight: 500, fontSize: "1.5rem" },
    h5: {
      fontFamily: headingsFontFamily,
      fontWeight: 500,
      fontSize: "1.25rem",
    },
    h6: { fontFamily: headingsFontFamily, fontWeight: 500, fontSize: "1rem" },
    button: { fontFamily: defaultFontFamily, textTransform: "none" },
  },
  palette: {
    primary: {
      main: "#1A3CB8",
      light: "#4D64E1",
      dark: "#092F93",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#D918A2",
      light: "#EF4CB7",
      dark: "#AC0887",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#E63946",
    },
    background: {
      default: "#FAFAFC",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#001D4B",
      secondary: "#405E7A",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#FAFAFC",
        },
      },
    },
  },
});

export default theme;

// /* Primary: Persian Blue */
// --primary-100: #F2F2FF;
// --primary-200: #BEC0FE;
// --primary-300: #8691F6;
// --primary-400: #4D64E1;
// --primary-500: #1A3CB8;
// --primary-600: #092F93;
// --primary-700: #02266F;
// --primary-800: #001D4B;
// --primary-900: #001126;

// /* Accent: Aphroditean Fuchsia */
// --accent-100: #FFF2F8;
// --accent-200: #FEBCE0;
// --accent-300: #FA85CB;
// --accent-400: #EF4CB7;
// --accent-500: #D918A2;
// --accent-600: #AC0887;
// --accent-700: #80026B;
// --accent-800: #53004A;
// --accent-900: #260024;

// /* Neutral */
// --neutral-100: #FAFAFC;
// --neutral-200: #E8E8EB;
// --neutral-300: #D6D6DA;
// --neutral-400: #C4C4C9;
// --neutral-500: #B2B3B8;
// --neutral-600: #8C8E93;
// --neutral-700: #686A6F;
// --neutral-800: #44474B;
// --neutral-900: #222426;
