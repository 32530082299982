import React, { createContext, useContext, useEffect, useReducer } from "react";
import {
  connectWebSocket,
  updateAuthenticationState,
  disconnectWebSocket,
} from "../services/websocketService";
import { AuthContext } from "../context/authContext";
import {
  fetchOlderNotifications,
  markNotificationAsRead,
  markAllNotificationsAsRead,
  markNotificationAsUnread,
  removeNotification as removeNotificationAPI,
  approveNotification as approveNotificationAPI,
} from "../services/api/notifications";
import { notificationsReducer } from "../reducers/notificationsReducer";
import { actionTypes } from "../statics/actionTypes";

const NotificationsContext = createContext();

export const initialState = {
  notifications: [],
  hasMore: true,
  isLoading: false,
  isError: false,
};

export const NotificationsProvider = ({ children }) => {
  const { isAuthenticated, userDetails } = useContext(AuthContext);
  const [state, dispatch] = useReducer(notificationsReducer, initialState);

  useEffect(() => {
    updateAuthenticationState(isAuthenticated);

    if (!isAuthenticated) {
      disconnectWebSocket();
      return;
    }

    // WebSocket connection logic remains unchanged
    // The disconnectWebSocket function is called when isAuthenticated becomes false
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const handleWebSocketMessage = async (data) => {
      try {
        switch (data.action) {
          case "user_notifications":
            dispatch({
              type: actionTypes.SET_INITIAL,
              payload: { notifications: data.notifications },
            });
            break;
          case "older_notifications":
            dispatch({
              type: actionTypes.FETCH_OLDER,
              payload: { notifications: data.notifications },
            });
            break;
          case "new_notification":
            dispatch({
              type: actionTypes.ADD_NEW,
              payload: { notification: data.notification },
            });
            break;
          case "notification_marked_as_read":
            dispatch({
              type: actionTypes.MARK_AS_READ,
              payload: { notificationId: data.notificationId },
            });
            break;
          case "notification_marked_as_unread":
            dispatch({
              type: actionTypes.MARK_AS_UNREAD,
              payload: { notificationId: data.notificationId },
            });
            break;
          case "all_notifications_marked_as_read":
            dispatch({
              type: actionTypes.MARK_ALL_AS_READ,
              payload: { userId: userDetails.userId },
            });
            break;
          case "notification_approved":
            dispatch({
              type: actionTypes.APPROVE,
              payload: {
                notificationId: data.notificationId,
                approved: data.approved,
              },
            });
            break;
          case "notification_removed":
            dispatch({
              type: actionTypes.REMOVE,
              payload: {
                notificationId: data.notificationId,
              },
            });
            break;
          default:
            break;
          // Add more cases as necessary for different actions
        }
      } catch (error) {
        console.error("Error handling WebSocket message:", error);
        dispatch({ type: actionTypes.LOADING_FAILURE });
      }
    };
    dispatch({ type: actionTypes.START_LOADING });

    connectWebSocket(handleWebSocketMessage)
      .then(() => {
        dispatch({ type: actionTypes.LOADING_SUCCESS });
      })
      .catch((error) => {
        console.error("WebSocket connection error:", error);
        dispatch({ type: actionTypes.LOADING_FAILURE });
      });

    return () => {
      disconnectWebSocket();
    };
  }, [isAuthenticated, userDetails?.userId]);

  const fetchOlder = (offset) => fetchOlderNotifications(offset);
  const markAsRead = (notificationId) => markNotificationAsRead(notificationId);
  const markAsUnread = (notificationId) =>
    markNotificationAsUnread(notificationId);
  const markAllAsRead = (userId) => markAllNotificationsAsRead(userId);
  const removeNotification = (notificationId) =>
    removeNotificationAPI(notificationId);
  const approveNotification = (notificationId, approved) =>
    approveNotificationAPI(notificationId, approved);

  return (
    <NotificationsContext.Provider
      value={{
        state,
        fetchOlder,
        markAsRead,
        markAsUnread,
        markAllAsRead,
        removeNotification,
        approveNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationsContext);
