import React, { createContext, useContext } from "react";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";
import defaultTheme from "./theme";
import localLogo from "../assets/logotest.png";
import { CONFIG } from "../services/apiConstants";
import { useApi } from "../services/useApi";

const CustomThemeContext = createContext({});

export const useCustomTheme = () => useContext(CustomThemeContext);

export const CustomThemeProvider = ({ children }) => {
  // Utilize useApi hook to fetch and observe configuration data
  const { data, isLoading, isError } = useApi(CONFIG);

  // Compute logo URL and company name from API data or fallbacks
  const logoUrl = data?.logoUrl || localLogo;
  const companyName = data?.company_name || "Wellness Panel";

  // Create a theme instance with dynamic values
  const theme = createTheme({
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      // Example: Adjusting theme colors based on configurations could go here
    },
    logo: logoUrl,
    companyName: companyName,
  });

  return (
    <CustomThemeContext.Provider value={theme}>
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider;
