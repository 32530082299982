import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoadingBackdropCirc from "../components/loading/LoadingBackdropCirc";
const Login = lazy(() => import("../pages/login/Login"));
// Import other public pages...

export default function PublicRoutes() {
  return (
    <Suspense fallback={<LoadingBackdropCirc open={true} />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* Define other public routes here */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Suspense>
  );
}
