import React from "react";
import Fade from "@mui/material/Fade";

const FadeTransition = ({ children, in: inProp, timeout = 500, ...props }) => {
  return (
    <Fade in={inProp} timeout={timeout} {...props}>
      <div>{children}</div>
    </Fade>
  );
};

export default FadeTransition;
