import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { logout } from "../../services/authService";
import { useNavigate, useLocation } from "react-router-dom";
import { useNotifications } from "../../context/NotificationsContext";
import {
  BottomNavigation,
  BottomNavigationAction,
  useTheme,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  capitalize,
  Slide,
  Badge,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { mobileItems } from "./menuItems";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ErrorIcon from "@mui/icons-material/Error";

function BottomNav() {
  const { userDetails, clearUserDetails, isAdmin, isManager } =
    useContext(AuthContext);
  const {
    state: { notifications },
    isLoading,
    isError,
  } = useNotifications();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (event, newValue) => {
    if (newValue === "user-menu") {
      setAnchorEl(event.currentTarget);
    } else if (newValue === "apps-menu") {
      navigate("/mobile-menu");
    } else {
      navigate(newValue);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
    clearUserDetails();
    handleClose();
    navigate("/login");
  };

  const handleProfileClick = () => {
    handleClose();
    navigate("/profile");
  };

  const renderErrorIcon = () => (
    <Tooltip title="Error loading notifications">
      <ErrorIcon color="error" />
    </Tooltip>
  );

  const renderBadgeContent = () => {
    if (isLoading) return <CircularProgress size={24} color="inherit" />;
    if (isError) return renderErrorIcon();
    return (notifications || []).filter((notification) => !notification.is_read)
      .length;
  };

  const unreadCount = renderBadgeContent();

  const filteredTabs = mobileItems.filter((item) => isAdmin || !item.isAdmin);

  return (
    <>
      <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <BottomNavigation
          value={location.pathname}
          onChange={handleChange}
          showLabels
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 999,
            "& .Mui-selected": {
              color: theme.palette.primary.main,
            },
            "& .MuiBottomNavigationAction-root": {
              color: theme.palette.action.active,
              maxWidth: "none",
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
            },
          }}
        >
          {filteredTabs.map((tab) => (
            <BottomNavigationAction
              key={tab.value}
              label={tab.label}
              value={tab.value}
              icon={
                location.pathname === tab.value
                  ? React.cloneElement(tab.icon, {
                      style: { color: theme.palette.primary.main },
                    })
                  : React.cloneElement(tab.OutlineIcon, {
                      style: { color: theme.palette.action.active },
                    })
              }
            />
          ))}
          {(isAdmin || isManager) && (
            <BottomNavigationAction
              value="apps-menu"
              icon={<i class="fi fi-rr-apps"></i>}
              label={"Menu"}
            />
          )}
          <BottomNavigationAction
            value="user-menu"
            icon={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  badgeContent={unreadCount}
                  color="error"
                >
                  <Avatar
                    alt={`${capitalize(userDetails?.firstName)} ${capitalize(
                      userDetails?.lastName
                    )}`}
                    src={userDetails.avatar}
                    sx={{ width: 32, height: 32 }}
                  />
                </Badge>
              </Box>
            }
          />
        </BottomNavigation>
      </Slide>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleProfileClick}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate("/notifications")}>
          <ListItemIcon>
            <Badge badgeContent={notifications.length} color="primary">
              <NotificationsIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText>Notifications</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default BottomNav;
