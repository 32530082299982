export const actionTypes = {
  SET_INITIAL: "SET_INITIAL_NOTIFICATIONS",
  FETCH_OLDER: "FETCH_OLDER_NOTIFICATIONS",
  ADD_NEW: "ADD_NEW_NOTIFICATION",
  MARK_AS_READ: "MARK_NOTIFICATION_AS_READ",
  MARK_AS_UNREAD: "MARK_NOTIFICATION_AS_UNREAD",
  MARK_ALL_AS_READ: "MARK_ALL_AS_READ",
  APPROVE: "APPROVE_NOTIFICATION",
  REMOVE: "REMOVE_NOTIFICATION",
  START_LOADING: "START_LOADING",
  LOADING_SUCCESS: "LOADING_SUCCESS",
  LOADING_FAILURE: "LOADING_FAILURE",
};
