import React, { useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider, AuthContext } from "./context/authContext";
import { RBACProvider } from "./context/RBACContext";
import { CustomThemeProvider } from "./theme/useCustomTheme";
import { PublicRoutes, PrivateRoutes } from "./routing";
import { SnackbarProvider, SnackbarContext } from "./context/SnackbarContext";
import SnackbarAlert from "./components/alert/SnackbarAlert";
import LoadingBackdropCirc from "./components/loading/LoadingBackdropCirc";
import { NotificationsProvider } from "./context/NotificationsContext";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "@flaticon/flaticon-uicons/css/all/all.css";

function App() {
  return (
    <CustomThemeProvider>
      <Router>
        <AuthProvider>
          <SnackbarProvider>
            <NotificationsProvider>
              <AppWrapper />
            </NotificationsProvider>
          </SnackbarProvider>
        </AuthProvider>
      </Router>
    </CustomThemeProvider>
  );
}

function AppWrapper() {
  const theme = useTheme();
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  const { snackbar, closeSnackbar } = useContext(SnackbarContext);

  // Loading indicator for initial authentication check
  if (isLoading) {
    return <LoadingBackdropCirc open={true} />;
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        minHeight: "100vh",
      }}
    >
      {isAuthenticated ? (
        <RBACProvider>
          <PrivateRoutes />
        </RBACProvider>
      ) : (
        <PublicRoutes />
      )}
      {/* Snackbar and potentially other UI elements that are independent of authentication status */}
      <SnackbarAlert
        open={snackbar.open}
        handleClose={closeSnackbar}
        severity={snackbar.severity}
        message={snackbar.message}
      />
    </Box>
  );
}

export default App;
