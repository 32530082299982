// notifications.js
import { sendMessage } from "../websocketService";

export const fetchOlderNotifications = (offset) => {
  try {
    const command = {
      action: "fetch_older_notifications",
      offset: offset,
      limit: 20,
    };
    sendMessage(command);
  } catch (error) {
    console.error("Failed to fetch older notifications", error);
  }
};

export const markNotificationAsRead = (notificationId) => {
  try {
    const command = {
      action: "mark_notification_as_read",
      notificationId: notificationId,
    };
    sendMessage(command);
  } catch (error) {
    console.error("Failed to mark notification as read", error);
  }
};

export const markNotificationAsUnread = (notificationId) => {
  try {
    const command = {
      action: "mark_notification_as_unread",
      notificationId: notificationId,
    };
    sendMessage(command);
  } catch (error) {
    console.error("Failed to mark notification as unread", error);
  }
};

export const markAllNotificationsAsRead = () => {
  try {
    const command = {
      action: "mark_all_notifications_as_read",
    };
    sendMessage(command);
  } catch (error) {
    console.error("Failed to mark all notifications as read", error);
  }
};

export const removeNotification = async (notificationId) => {
  try {
    // Construct the command to send over WebSocket
    const command = {
      action: "remove_notification",
      notificationId: notificationId,
    };

    // Send the command over WebSocket
    sendMessage(command);
  } catch (error) {
    console.error("Failed to remove notification", error);
  }
};

export const approveNotification = (notificationId, approved) => {
  try {
    const command = {
      action: "approve_notification",
      notificationId,
      approved,
    };
    sendMessage(command);
  } catch (error) {
    console.error("Failed to approve or reject notification", error);
  }
};
