import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  useTheme,
  Box,
} from "@mui/material";
import { AuthContext } from "../../context/authContext";
import { managerItems, adminItems } from "../../components/menu/menuItems";
import FadeTransition from "../../components/effects/FadeTransition";

function MenuList({ handleClose = () => {} }) {
  const { isAdmin, isManager } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleNavigation = (path) => {
    handleClose();
    setTimeout(() => {
      navigate(path);
    }, 300); // Give some time for the menu to close
  };

  const renderMenuItems = (items) => (
    <Grid container spacing={2}>
      {items.map((item, index) => (
        <Grid item xs={4} key={index}>
          <FadeTransition in={true}>
            <Card
              elevation={0}
              sx={{
                ":hover": {
                  backgroundColor: theme.palette.action.hover,
                },
                backgroundColor: theme.palette.action.hover,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: theme.spacing(1),
                height: 150,
              }}
              onClick={() => handleNavigation(item.path)}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: 0,
                  height: "100%",
                }}
              >
                {item.icon}
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  sx={{ marginTop: theme.spacing(1) }}
                >
                  {item.label}
                </Typography>
              </CardContent>
            </Card>
          </FadeTransition>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Box sx={{ padding: theme.spacing(2) }}>
      {isManager && (
        <>
          <Typography variant="h4" gutterBottom>
            Manager Menu
          </Typography>
          {renderMenuItems(managerItems)}
        </>
      )}
      {isAdmin && (
        <>
          <Typography variant="h4" gutterBottom>
            Admin
          </Typography>
          {renderMenuItems(adminItems)}
        </>
      )}
    </Box>
  );
}

export default MenuList;
