import React from "react";
import {
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const VouchersRequestNotification = ({ notification, isAdmin }) => {
  const { client_id, client_name, treatments } = notification.data.request_data;
  const navigate = useNavigate();

  // Group treatments by their categories
  const treatmentsByCategory = treatments.reduce((acc, treatment) => {
    const { category, name, quantity } = treatment;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push({ name, quantity });
    return acc;
  }, {});

  return (
    <>
      <Typography variant="body2">{notification.message}</Typography>
      <Divider sx={{ my: 1 }} />
      <Typography variant="h5">Voucher Treatments</Typography>
      <Grid container spacing={2}>
        {Object.entries(treatmentsByCategory).map(([category, treatments]) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={category}>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              {category}
            </Typography>
            <List dense>
              {treatments.map((treatment, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${treatment.quantity} x ${treatment.name}`}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
      <Link
        component="button"
        variant="subtitle1"
        onClick={() =>
          navigate(
            isAdmin ? `/admin/clients/${client_id}` : `/clients/${client_id}`
          )
        }
        underline="none"
      >
        Go to {client_name}'s profile
      </Link>
    </>
  );
};

export default VouchersRequestNotification;
