import React from "react";
import { Backdrop, CircularProgress, Typography, Box } from "@mui/material";

const LoadingBackdropCirc = ({ open, text = "" }) => {
  return (
    <Backdrop
      open={open}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: "#fff" }}
    >
      <Box textAlign="center">
        <CircularProgress color="inherit" />
        {text && (
          <Typography variant="h6" sx={{ mt: 2 }}>
            {text}
          </Typography>
        )}
      </Box>
    </Backdrop>
  );
};

export default LoadingBackdropCirc;
