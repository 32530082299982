import React from "react";
import {
  Typography,
  Link,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PeopleIcon from "@mui/icons-material/People";

const ArrivalRequestNotification = ({ notification, isAdmin }) => {
  const {
    client_id,
    client_name,
    location_name,
    date,
    start_time,
    treatments,
    category_usage,
    companions,
  } = notification.data.request_data;
  const navigate = useNavigate();

  // Combine treatments by category ID for display
  const treatmentsByCategory = treatments.reduce((acc, treatment) => {
    acc[treatment.category_id] = acc[treatment.category_id] || [];
    acc[treatment.category_id].push(treatment);
    return acc;
  }, {});

  const formatUsage = (amount) => {
    if (amount === 0) return "No Vouchers used";
    return `${amount} Voucher${amount > 1 ? "s" : ""} used`;
  };

  const formatPeopleUsed = (amount) => {
    if (amount === 0) return "No person used";
    return `${amount} person${amount > 1 ? "s" : ""} used`;
  };

  const formatDate = (dateStr, timeStr) => {
    const dateObj = new Date(dateStr + "T" + timeStr);
    return dateObj.toLocaleString("en-US", {
      weekday: "long", // "Monday"
      day: "numeric", // "1"
      month: "long", // "January"
      year: "numeric", // "2020"
      hour: "numeric", // "4"
      minute: "2-digit", // "00"
      hour12: true, // "AM/PM"
    });
  };

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        {notification.message}
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Typography variant="h6" gutterBottom>
        Arrival for {client_name}
      </Typography>
      <Stack direction="row" spacing={2} alignItems="center" mb={2}>
        <Typography
          variant="subtitle2"
          component="div"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <PeopleIcon color="action" sx={{ mr: 0.5 }} />
          {companions}
        </Typography>
        <Typography
          variant="subtitle2"
          component="div"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <LocationOnIcon color="action" sx={{ mr: 0.5 }} /> {location_name}
        </Typography>

        <Typography
          variant="subtitle2"
          component="div"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <AccessTimeIcon color="action" sx={{ mr: 0.5 }} />
          {formatDate(date, start_time)}
        </Typography>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Services Used
          </Typography>
        </Grid>
        {category_usage.map((category, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Box mb={2}>
              <Typography variant="subtitle2">{category.name}</Typography>
              <Typography variant="body2">
                {formatUsage(category.used)}
              </Typography>
              <List dense>
                {treatmentsByCategory[category.category_id]?.map(
                  (treatment, idx) => (
                    <ListItem key={idx}>
                      <ListItemText
                        primary={`${treatment.name}`}
                        secondary={formatPeopleUsed(treatment.amount_used)}
                      />
                    </ListItem>
                  )
                )}
              </List>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box mt={2}>
        <Link
          component="button"
          variant="subtitle1"
          onClick={() =>
            navigate(
              isAdmin ? `/admin/clients/${client_id}` : `/clients/${client_id}`
            )
          }
          underline="none"
        >
          View {client_name}'s Profile
        </Link>
      </Box>
    </>
  );
};

export default ArrivalRequestNotification;
