import React from "react";
import {
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  Grid,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { hoursDurationFormatter } from "../../../utils/formatters";

const AppointmentUpdatedNotification = ({ notification, isAdmin }) => {
  const {
    client_id,
    client_name,
    appointment_id,
    location_name,
    room,
    start_time,
    duration,
    old_services,
    updated_services,
  } = notification.data.request_data;
  const navigate = useNavigate();

  const renderServices = (services) => {
    return Object.entries(services).map(([category, items]) => (
      <ListItem key={category}>
        <ListItemText
          primary={category}
          secondary={Array.isArray(items) ? items.join(", ") : items}
        />
      </ListItem>
    ));
  };

  return (
    <Box p={2}>
      <Typography variant="body2">{notification.message}</Typography>
      <Grid container spacing={2} mt={2}>
        <Box display="flex" alignItems="center" m={1}>
          <i className="fi fi-rr-map-marker" style={{ marginRight: 8 }}></i>
          <Typography variant="subtitle1" gutterBottom>
            {location_name}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" m={1}>
          <i className="fi fi-rr-door-closed" style={{ marginRight: 8 }}></i>
          <Typography variant="subtitle1" gutterBottom>
            {room}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" m={1}>
          <i className="fi fi-rr-clock" style={{ marginRight: 8 }}></i>
          <Typography variant="subtitle1" gutterBottom>
            {start_time}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" m={1}>
          <i className="fi fi-rr-time-add" style={{ marginRight: 8 }}></i>
          <Typography variant="subtitle1" gutterBottom>
            {hoursDurationFormatter(duration)}
          </Typography>
        </Box>
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            <i className="fi fi-rr-scroll-old" style={{ marginRight: 8 }}></i>
            Old Services
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Membership Services:
          </Typography>
          <List>{renderServices(old_services?.membership_services)}</List>
          <Typography variant="subtitle2" gutterBottom>
            Voucher Services:
          </Typography>
          <List>{renderServices(old_services?.voucher_services)}</List>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            <i className="fi fi-rr-refresh" style={{ marginRight: 8 }}></i>
            Updated Services
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Membership Services:
          </Typography>
          <List>{renderServices(updated_services?.membership_services)}</List>
          <Typography variant="subtitle2" gutterBottom>
            Voucher Services:
          </Typography>
          <List>{renderServices(updated_services?.voucher_services)}</List>
        </Grid>
      </Grid>

      <Box mt={2}>
        <Link
          component="button"
          variant="subtitle1"
          onClick={() =>
            navigate(
              isAdmin
                ? `/admin/appointments/${appointment_id}`
                : `/appointments/${appointment_id}`
            )
          }
          underline="none"
        >
          View Appointment Details
        </Link>
      </Box>
    </Box>
  );
};

export default AppointmentUpdatedNotification;
