import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  capitalize,
} from "@mui/material";
import { AuthContext } from "../../context/authContext";
import { logout } from "../../services/authService";
import { useNotifications } from "../../context/NotificationsContext";
import NotificationList from "../../components/notifications/NotificationsList";
import ErrorIcon from "@mui/icons-material/Error";
import CircularProgress from "@mui/material/CircularProgress";

function UserPopover({ anchorEl, handleClose }) {
  const { userDetails, clearUserDetails } = useContext(AuthContext);
  const {
    state: { notifications },
    isLoading,
    isError,
  } = useNotifications();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleLogout = async () => {
    await logout();
    clearUserDetails();
    handleClose();
    navigate("/login");
  };

  const handleProfileClick = () => {
    handleClose();
    navigate("/profile");
  };

  const renderErrorIcon = () => <ErrorIcon color="error" />;

  const renderBadgeContent = () => {
    if (isLoading) return <CircularProgress size={24} color="inherit" />;
    if (isError) return renderErrorIcon();
    return (notifications || []).filter((notification) => !notification.is_read)
      .length;
  };

  const unreadCount = renderBadgeContent();

  const handleNotificationClick = () => {
    setExpanded(true);
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        sx: {
          width: 600,
          maxHeight: 600,
          boxShadow:
            "0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1)",
          borderRadius: 2,
          transition: "width 0.3s ease-in-out",
        },
      }}
    >
      <Box
        sx={{ padding: 2, width: "100%", maxHeight: "100%", overflowY: "auto" }}
      >
        <List>
          <ListItem
            secondaryAction={
              <>
                <IconButton onClick={handleProfileClick}>
                  <i className="fi fi-rs-user"></i>
                </IconButton>
                <IconButton onClick={handleLogout}>
                  <i className="fi fi-rr-exit"></i>
                </IconButton>
              </>
            }
          >
            <ListItemText
              primary={`${capitalize(userDetails?.firstName)} ${capitalize(
                userDetails?.lastName
              )}`}
              secondary={userDetails?.groups}
            />
          </ListItem>
        </List>
        <NotificationList
          handlePopoverClose={handleClose}
          handleNotificationClick={handleNotificationClick}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </Box>
    </Popover>
  );
}

export default UserPopover;
