// services/websocketService.js

// Determine the base URL based on window.location (simplistic approach)
// const isDev = true;
// const isSecure = window.location.protocol === "https:";
// const wsProtocol = isSecure ? "wss://" : "ws://";
// const baseURL = wsProtocol + `${window.location.host}/ws/notifications`;
// const notificationsPath = "ws://127.0.0.1:8000/ws/notifications/";
// const url = isDev ? notificationsPath : baseURL;
// const tempProd = "wss://api.wellness-panel.com/ws/notifications/";
// const url = tempProd;

const url = process.env.REACT_APP_WS_BASE_URL;

let isAuthenticated = false;
let socket = null; // Declare the socket variable at the top before using it
let reconnectIntervalId = null;
const RECONNECT_DELAY = 5000; // Reconnect delay in milliseconds
let heartbeatIntervalId = null;

// Utility function to safely clear existing reconnect interval
const clearReconnectInterval = () => {
  if (reconnectIntervalId !== null) {
    clearTimeout(reconnectIntervalId);
    reconnectIntervalId = null;
  }
};

const clearHeartbeatInterval = () => {
  if (heartbeatIntervalId !== null) {
    clearInterval(heartbeatIntervalId);
    heartbeatIntervalId = null;
  }
};

const createWebSocket = (url, callback) => {
  // Ensure any existing socket is properly closed before creating a new one
  if (socket !== null) {
    socket.close();
  }

  socket = new WebSocket(url);

  socket.onopen = () => {
    //console.log(`WebSocket connected to ${url}`);
    clearReconnectInterval();
    startHeartbeat();
  };

  socket.onerror = (error) => {
    console.error("WebSocket error:", error);
  };

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    if (callback && typeof callback === "function") {
      callback(data);
    } else {
      console.error("callback is not a function");
    }
  };

  socket.onclose = (event) => {
    //console.log(`WebSocket disconnected: ${event.reason}`);
    socket = null; // Reset socket to null on close
    attemptReconnect(url, callback); // Attempt to reconnect
  };
};

const startHeartbeat = () => {
  clearHeartbeatInterval();
  heartbeatIntervalId = setInterval(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      sendMessage({ action: "ping" });
    }
  }, 30000); // Send a ping every 30 seconds
};

const attemptReconnect = (url, callback) => {
  if (!isAuthenticated) {
    console.log("User not authenticated. Aborting WebSocket reconnection.");
    return; // Exit without setting a reconnection attempt
  }

  clearReconnectInterval(); // Ensure no previous reconnect attempts are pending
  reconnectIntervalId = setTimeout(() => {
    console.log("Attempting WebSocket reconnection...");
    createWebSocket(url, callback); // Directly call createWebSocket for reconnection
  }, RECONNECT_DELAY);
};

export const connectWebSocket = (callback) => {
  return new Promise((resolve, reject) => {
    if (socket !== null) {
      console.warn(
        "WebSocket connection is already active. Skipping new connection attempt."
      );
      resolve("Existing connection reused."); // Resolve since connection is already active
      return;
    }

    // Callback wrapper to include promise resolution
    const wrappedCallback = (data) => {
      if (data.action === "connected") {
        // Assuming your server sends a connected acknowledgment
        resolve("WebSocket connected successfully.");
      }
      if (callback && typeof callback === "function") {
        callback(data);
      } else {
        console.error("callback is not a function");
        reject("Callback is not a function");
      }
    };

    createWebSocket(url, wrappedCallback);

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      reject(error); // Reject the promise on WebSocket error
    };

    // Adjust socket.onopen in createWebSocket to call wrappedCallback with a specific message
    // or handle the promise resolution directly there if you prefer
  });
};

export const updateAuthenticationState = (newState) => {
  isAuthenticated = newState;
};

export const sendMessage = (message) => {
  if (!socket || socket.readyState !== WebSocket.OPEN) {
    console.error("WebSocket is not connected. Unable to send message.");
    return;
  }
  socket.send(JSON.stringify(message));
};

export const disconnectWebSocket = () => {
  if (socket) {
    socket.close(); // Explicitly close the WebSocket connection
  }
  clearReconnectInterval(); // Clear any pending reconnection attempts
};
