import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingBackdropCirc from "../loading/LoadingBackdropCirc";
import { AuthContext } from "../../context/authContext";

export function ProtectedRoute({
  children,
  adminOnly = false,
  managerOnly = false,
}) {
  const { userDetails, isAuthenticated, isLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return;

    if (!isAuthenticated) {
      navigate("/login", { replace: true });
      return;
    }

    if (adminOnly && !userDetails?.groups?.includes("Admin")) {
      navigate("/calendar", { replace: true });
      return;
    }

    if (managerOnly && !userDetails?.groups?.includes("Manager")) {
      navigate("/calendar", { replace: true });
      return;
    }
  }, [
    userDetails,
    isAuthenticated,
    isLoading,
    navigate,
    adminOnly,
    managerOnly,
  ]);

  if (isLoading || !isAuthenticated) {
    return <LoadingBackdropCirc open={true} />;
  }

  return children;
}
