import React, { createContext, useContext, useState, useCallback } from "react";
import dayjs from "dayjs";
import { roundToNearest15Min } from "../utils/formatters";

const ScheduleFormContext = createContext();

export const useScheduleFormContext = () => useContext(ScheduleFormContext);

const initialFormState = {
  client: "",
  date: dayjs().format("YYYY-MM-DD"),
  start_time: roundToNearest15Min(dayjs()).format("HH:mm"),
  duration: 30,
  location: "",
  room: 0,
  memberships: [],
  vouchers: [],
  companions: 0,
  comment: "",
  membership_services: [],
  vouchers_services: [],
};

export const ScheduleFormProvider = ({ children }) => {
  const [scheduleFormState, setScheduleFormState] = useState(initialFormState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const updateScheduleFormState = (updates) => {
    setScheduleFormState((prev) => ({ ...prev, ...updates }));
  };

  const resetScheduleFormState = () => {
    setScheduleFormState(initialFormState);
  };

  const setInitialLocation = (locationId) => {
    setScheduleFormState((prev) => ({ ...prev, location: locationId }));
  };

  const handleDrawerOpen = useCallback(() => setDrawerOpen(true), []);
  const handleDrawerClose = useCallback(() => setDrawerOpen(false), []);

  const value = {
    scheduleFormState,
    updateScheduleFormState,
    resetScheduleFormState,
    setInitialLocation,
    isSubmitting,
    setIsSubmitting,
    isDrawerOpen,
    handleDrawerOpen,
    handleDrawerClose,
  };

  return (
    <ScheduleFormContext.Provider value={value}>
      {children}
    </ScheduleFormContext.Provider>
  );
};
