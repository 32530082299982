// api/useApi.js
import useSWR from "swr";
import axiosInstance from "./apiService"; // Adjust the path as necessary

const fetcher = (url) => axiosInstance.get(url).then((res) => res.data);

export function useApi(
  endpoint,
  { initialData, refreshInterval = 0, ...config } = {}
) {
  const { data, error, mutate } = useSWR(
    endpoint ? `${endpoint}` : null,
    fetcher,
    {
      initialData,
      revalidateOnFocus: true, // Revalidate when window gains focus
      refreshInterval, // Refresh data every interval in milliseconds (0 to disable)
      dedupingInterval: 5000, // Deduplicate requests within 5 seconds
      errorRetryInterval: 5000, // Retry failed requests after 5 seconds
      errorRetryCount: 5, // Retry failed requests up to 5 times
      onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
        // Never retry on 404.
        if (error.status === 404) return;

        // Only retry up to 10 times.
        if (retryCount >= 10) return;

        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
      ...config,
    }
  );

  const isLoading = !data && !error;

  return {
    data,
    isLoading,
    isError: error,
    mutate,
  };
}
